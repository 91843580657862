import { createMuiTheme } from '@material-ui/core/styles'

const textFont = 'Source Sans Pro, sans-serif'
const headingFont = 'Source Sans Pro, sans-serif'
const scriptFont = 'Longhand, cursive'
//test push
const white = '#fff'
const offWhite = '#f4f1ee'
const black = '#000'
const warmgrey = '#4d4139'
const primaryMain = '#463C32'
const primaryLight = '#463c3226' //PAUL 07/12/2023 Pas modifié au final car sinon trop d'impact (ET nouvelle  #a63a00)
const primaryDark = '#463C32'
const secondaryMain = '#ff5900' //PAUL 07/12/2023 Ancienne couleur = vert = #5b9127 ET nouvelle = orange #ff5900
const secondaryMainStrong = '#a63a00'
const secondaryLight = '#97bf0d'
const secondaryDark = '#859E2C'
const grey = '#463c324d'
const greyLight = primaryLight
const greyMedium = '#463c324d'
const greyText = '#463c32b3'

const imp = '!important'

const f11 = `11px ${imp}`
const f12 = `12px ${imp}`

const f14 = `14px ${imp}`
const f16 = `16px ${imp}`
const f18 = `18px ${imp}`

const f20 = `20px ${imp}`
const f28 = `28px ${imp}`
const f36 = `36px ${imp}`

const captionS = {
  fontSize: f11,
}
const captionM = {
  fontSize: f12,
}

const bodyS = {
  fontSize: f14,
  color: warmgrey,
}
const bodyM = {
  fontSize: f16,
  fontWeight: 400,
  lineHeight: 1.5,
  color: warmgrey,
}
const bodyL = {
  fontSize: f18,
  color: warmgrey,
}

const headingS = {
  fontFamily: `${headingFont} ${imp}`,
  fontWeight: 900,
  fontSize: f16,
  color: warmgrey,
  '@media (min-width: 1024px)': {
    fontSize: f18,
  },
}
const headingM = {
  fontFamily: `${headingFont} ${imp}`,
  fontWeight: 900,
  fontSize: f20,
  color: warmgrey,
  '@media (min-width: 1024px)': {
    fontSize: f28,
  },
}
const headingL = {
  fontFamily: `${headingFont} ${imp}`,
  fontWeight: 900,
  fontSize: f28,
  color: warmgrey,
  '@media (min-width: 1024px)': {
    fontSize: f36,
  },
}

const hpTitle = {
  ...headingM,
  textAlign: 'center',
  color: `${warmgrey} ${imp}`,
  '&:empty:after': {
    display: `none ${imp}`,
  },
}

const badgeNeutral = {
  ...captionM,
  padding: `3px 8px ${imp}`,
  borderRadius: `4px ${imp}`,
  color: `${greyMedium} ${imp}`,
  border: `1px solid ${greyMedium} ${imp}`,
  backgroundColor: `${greyLight} ${imp}`,
  fontWeight: `400 ${imp}`,
}

const customSelect = {
  color: `${warmgrey} ${imp}`,
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${primaryLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${primaryLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  settings: {
    defaultCountry: 'DE',
    priceFormat: 'comma_two_decimals',
  },
  themeFont: {
    main: 'Source Sans Pro',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: textFont,
    h1: {
      ...headingL,
    },
    h2: {
      ...headingM,
    },
    h3: {
      ...headingS,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    body1: {
      ...bodyM,
    },
    body2: {
      ...bodyM,
    },
    caption: {
      ...bodyS,
    },
    button: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 700,
      fontFamily: textFont,
    },
  },
  palette: {
    common: {
      black: warmgrey,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      light: white,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: white,
    },
    success: {
      main: '#188803',
    },
    error: {
      main: '#c61112',
    },
    warning: {
      main: '#c65200',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.label': {
          ...captionS,
        },
        '.select': {
          ...customSelect,
        },
        '.banner': {
          backgroundColor: `${offWhite} ${imp}`,
          color: `${warmgrey} ${imp}`,
        },
        '.date_form': {
          background: `none ${imp}`,
          display: `none ${imp}`,
        },
        '.header': {
          '&__logo': {
            //height: `30px ${imp}`,
            '@media (max-width: 768px)': {
              //maxWidth: `60vw !important`,
            },
          },
          '&__cta': {
            '&.MuiButton-root': {
              padding: `0 ${imp}`,
            },
            '&--account': {
              '& .MuiSvgIcon-root': {
                backgroundSize: `contain ${imp}`,
                backgroundColor: `transparent ${imp}`,
                background:
                  'transparent url("/images/vaude/account.svg") no-repeat',
                '&>path': {
                  display: `none ${imp}`,
                },
              },
            },
            '&--faq': {
              '& .MuiSvgIcon-root': {
                backgroundSize: `contain ${imp}`,
                backgroundColor: `transparent ${imp}`,
                background:
                  'transparent url("/images/vaude/faq.svg") no-repeat',
                '&>path': {
                  display: `none ${imp}`,
                },
              },
            },
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {},
        },
        '.basket__btn': {
          '& .cart-badge': {
            borderRadius: `50% ${imp}`,
            borderColor: `${secondaryMain} ${imp}`,
          },
        },
        '.page--home': {},
        '.hero__home': {
          '& .MuiGrid-container': {
            '@media (max-width: 755px)': {
              margin: '20px !important',
            },
          },

          '& .title': {
            ...headingL,
          },
          '& .tagline': {
            ...bodyL,
          },
        },
        '.daterange': {
          '&__btn': {
            fontWeight: `700 ${imp}`,
            fontSize: f16,
            padding: `10px ${imp}`,
          },
        },
        '.hero': {
          '&__toptitle': {
            color: `${secondaryMain} ${imp}`,
          },
          '&__title': {
            ...headingM,
            color: `${white} ${imp}`,
          },
        },
        '.shipping-infos': {
          '& .MuiBox-root': {
            paddingTop: `7.5px ${imp}`,
            paddingBottom: `8px ${imp}`,
          },
          '& .MuiTypography-body1': {
            fontSize: f12,
            fontWeight: `700 ${imp}`,
          },
          '& .MuiTypography-body2': {
            ...captionS,
          },
        },
        '.cart-shipping--absolute': {
          '& .shipping-infos .MuiBox-root': {
            padding: `0 ${imp}`,
          },
        },
        '.productCard': {
          '&__title': {
            ...bodyS,
            textTransform: `none ${imp}`,
            fontWeight: `400 ${imp}`,
          },
          '&__price': {
            ...bodyS,
            fontWeight: `400 ${imp}`,
          },
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__desc': {
            padding: `8px 0 ${imp}`,

            '& span': {
              fontSize: f14,
            },
          },
          '&__add': {
            margin: `0 ${imp}`,
          },
        },
        '.product': {
          '&__title': {
            ...headingS,
          },
          '&__price': {
            ...headingS,
          },
          '&__amount': {
            '& .price__amount>span': {
              marginRight: '-12px !important',
            },
          },
          '&__oldPrice': {
            '& .price__amount>span': {
              marginRight: '-12px !important',
            },
          },
          '&__details': {
            '& .take_back_description': {
              fontWeight: `700 ${imp}`,
            },
          },
        },
        '.packSport': {
          '&__head': {
            borderRadius: `0 ${imp}`,
          },
          '& .pack__link': {
            color: `${primaryMain} ${imp}`,
            border: `2px solid ${primaryMain} ${imp}`,
            background: `transparent ${imp}`,
            '&:hover, &:focus': {
              background: `${primaryLight} ${imp}`,
            },
          },
          '& .pack__title': {
            '& .MuiTypography-root': {
              ...bodyL,
            },
          },
          '& .pack__desc': {
            ...bodyS,
          },
        },
        '.pack': {
          '&__title': {
            '& .MuiTypography-root': {
              ...headingS,
            },
          },
          '&__products': {
            '&-title': {
              // '&:after': {
              //   ...headingBorder,
              // },
            },
          },

          '&__price': {
            display: 'none !important',
          },
        },
        '.commitment': {
          background: `${greyLight} ${imp}`,
          '&__title': {
            ...bodyL,
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: `0 ${imp}`,
            '&:last-child': {
              marginBottom: `48px ${imp}`,
            },
          },
          '&__question': {
            '&.MuiButton-root': {
              ...bodyL,
              fontWeight: 'normal !important',
              marginBottom: `0 ${imp}`,
              borderBottom: `1px solid ${grey} ${imp}`,
              borderRadius: `0 ${imp}`,
              padding: `20px 48px 20px 8px ${imp}`,
              backgroundSize: '1.5rem',
              backgroundColor: `${greyLight} ${imp}`,
              background:
                'no-repeat right 0.5rem center url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiICB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGQ9Ik0xOS4wOSwxMWgtNlY1YTEsMSwwLDAsMC0yLDB2NmgtNmExLDEsMCwwLDAsMCwyaDZ2NmExLDEsMCwwLDAsMiwwVjEzaDZhMSwxLDAsMCwwLDAtMloiLz48L3N2Zz4=")',
              '&:hover, &:focus, &[aria-expanded=true]': {
                color: `${warmgrey} ${imp}`,
                backgroundColor: `${greyLight} ${imp}`,
              },
              '&[aria-expanded=true]': {
                border: `0 ${imp}`,
                backgroundColor: `${greyLight} ${imp}`,
                backgroundImage:
                  'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE3LjA5LDEzaC0xMGExLDEsMCwwLDEsMC0yaDEwYTEsMSwwLDAsMSwwLDJaIi8+PC9zdmc+")',
              },
            },
          },
          '&__btn': {
            display: `none ${imp}`,
          },
          '&__answer': {
            ...bodyM,
            borderRadius: `0 ${imp}`,
            marginBottom: `0 ${imp}`,
            padding: `8px 8px 1rem 8px ${imp}`,
            border: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
            color: `${greyText} ${imp}`,
          },
          '&-page': {
            '&__cat': {
              marginTop: `30px !important`,
              // '&:after': {
              //   ...headingBorder,
              // },
            },
          },
        },
        '.product__area': {},
        '.testimonial': {
          '&__desc': {
            fontFamily: scriptFont,
            fontSize: f18,
            lineHeight: 1.25,
          },
        },
        '.hiw, .testimonial': {
          '&__title': {
            fontSize: f20,
          },
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        },
        '.discover-product': {
          '&__content': {
            textAlign: `left ${imp}`,
            color: `${warmgrey} ${imp}`,
            fontWeight: `700 ${imp}`,
          },
          '&__title': {
            ...bodyM,
            color: `${warmgrey} ${imp}`,
            fontWeight: `700 ${imp}`,
          },
          '&__price': {
            fontWeight: `700 ${imp}`,
          },
        },
        '.quantitySelector': {
          '&__btn': {
            borderRadius: `50% ${imp}`,
          },
        },
        '.sizes': {
          '&__btn': {
            color: `${black} ${imp}`,
            backgroundColor: `${white} ${imp}`,
            border: `1px solid ${primaryMain} ${imp}`,
            '&.selected': {
              border: `1px solid ${secondaryMain} ${imp}`,
              color: `${black} ${imp}`,
              backgroundColor: `${white} ${imp}`,
            },
            '&:hover': {
              color: `initial ${imp}`,
              backgroundColor: `initial ${imp}`,
            },
          },
          '&__chip': {
            backgroundColor: `${black} ${imp}`,
          },
          '&__list>button': {
            color: black,
            backgroundColor: `${white} ${imp}`,
            border: `1px solid ${primaryMain} ${imp}`,
            '&:hover': {
              color: black,
              backgroundColor: `${white} ${imp}`,
            },
            '&.selected': {
              borderColor: `${secondaryMain} ${imp}`,
            },
          },
        },
        '.size-card': {
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__title': {
            ...bodyM,
            fontWeight: `400 ${imp}`,
          },
        },
        '.size-modal': {
          '& .MuiDialog-paper': {
            '@media (min-width: 680px)': {
              minWidth: `600px ${imp}`,
            },
          },
          '&__head': {
            backgroundColor: `transparent ${imp}`,
          },
          '&__title': {
            ...bodyS,
          },
          '&__close': {},
          '&__content': {
            borderColor: `${greyMedium} ${imp}`,
          },
        },
        '.section__spacer': {
          padding: `24px 0 ${imp}`,
        },
        '.section-menu': {
          padding: `0 ${imp}`,
          '& .MuiTypography-root': {
            fontWeight: `700 ${imp}`,
          },
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-hiw': {
          '&__title': {
            paddingTop: `0 ${imp}`,
          },
          '&__spacing': {
            padding: `32px 0 ${imp}`,
          },
          '& .MuiButtonBase-root': {
            backgroundColor: `${secondaryMain} ${imp}`,
            '&:hover': {
              color: `${white} ${imp}`,
              backgroundColor: `${secondaryMainStrong} ${imp}`,
            },
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          '&__subtitle': {
            ...hpTitle,
          },
        },
        '.section-testimonials': {},
        '.flexContent': {},
        '.funnel': {
          '&__header': {
            backgroundColor: `${primaryMain} ${imp}`,
            '& .MuiTypography-root': {
              color: `${white} ${imp}`,
            },
          },
          '&__nav': {
            '@media (min-width: 768px)': {
              fontSize: f14,
            },
            '&--active': {
              color: `${primaryMain} ${imp}`,
              borderColor: `${primaryMain} ${imp}`,
            },
          },
        },
        '.payment-bycard': {
          '@media (min-width: 768px)': {
            width: `600px ${imp}`,
          },
        },
        '.footer': {
          '&__top': {},
          '&__menu': {
            '@media (max-width: 480px)': {
              display: `flex ${imp}`,
              flexDirection: `column ${imp}`,
            },
          },
          '&__copyright': {
            '@media (max-width: 480px)': {
              paddingTop: `0px ${imp}`,
            },
          },
          '&__bottom': {
            paddingBottom: `51px ${imp}`,
            '@media (max-width: 480px)': {
              paddingBottom: `31px ${imp}`,
            },
          },
        },
        '.page': {
          '&__hero': {
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
          '& .product__prices': {
            //  display: `flex ${imp}`,
            alignItems: `center ${imp}`,
            '& .product__price--start': {
              paddingRight: `6px ${imp}`,
            },
            '& .product__price--original': {
              paddingLeft: `6px ${imp}`,
              borderLeft: `1px solid ${black}`,
            },
          },
          '&--cms': {
            '& .page-content': {
              '& .section_container': {
                '@media (min-width: 1024px)': {
                  height: '560px',
                  minHeight: 'auto',
                },
              },
              '& .hero_image': {
                height: '560px',
              },
            },
          },
        },
        '.account, .contact-page, .checkout': {
          '& + .footer': {
            backgroundColor: `${greyLight} ${imp}`,
          },
        },
        '.cart': {
          '&-item': {
            '& .price': {
              '&__amount,&__duration': {
                color: `${black} ${imp}`,
              },
            },
          },
        },
        //Paul reprise Vaude jeudi 8 février
        '.hero_content>h1': {
          color: `${white} ${imp}`,
        },
        '.link-class': {
          textDecoration: `underline ${imp}`,
          color: '-webkit-link',
          cursor: 'pointer',
        },
        '.state__label': {
          '& >svg': {
            width: `17px ${imp}`,
          },
        },
        '.take_back_states_informations': {
          '& .take_back_states_informations__close': {
            color: `${warmgrey} ${imp}`,
            background: 'none',
            '&:hover': {
              color: `${offWhite} ${imp}`,
              background: 'none',
            },
          },
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: `30px ${imp}`, //Paul 07/12/2023 ancienne valeur 2
        textTransform: `none`,
        '&:focus': {},
        '&.Mui-disabled': {
          borderColor: `transparent ${imp}`,
        },
      },
      label: {},
      sizeSmall: {
        padding: `5px 15px`,
      },
      sizeLarge: {
        padding: `10px 30px`,
        fontSize: f16,
      },
      contained: {
        padding: `10px 30px`,
        '&:hover, &:focus, &:active, &.selected': {},
        '&[disabled]': {},
      },
      containedPrimary: {
        backgroundColor: `${secondaryMain} ${imp}`,
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${secondaryMainStrong} ${imp}`,
        },
      },
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${secondaryMainStrong} ${imp}`,
        },
      },
      outlined: {
        // color: `${black}`,
        // backgroundColor: `${white}`,
        '&:hover, &:focus, &:active, &.selected': {
          // color: `${secondaryMain}`,
          // backgroundColor: `${white} ${imp}`,
        },
      },
      outlinedPrimary: {},
      outlinedSizeSmall: {},
    },
    MuiTab: {
      root: {
        borderRadius: `2px ${imp}`,
        padding: `5px 15px ${imp}`,
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': {
          color: `${primaryMain} ${imp}`,
        },
      },
    },
  },
})

export default theme
